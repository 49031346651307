<template>
  <div class="fuiler">
    <Header class="Header"></Header>
    <!--    <div class="nav_block"></div>-->
    <div class="main">
      <div class="Carousel">
        <div class="left"></div>
        <div class="right">
          <div class="activity">
            活动期数<span>{{ TopData.TitleNumber }}</span> 当季主题<span>{{
              TopData.Title
            }}</span>
            <div class="start"><i></i>{{ TopData.StyleClass }}</div>
          </div>
          <div
            class="data"
            :style="'background-image: url(' + TopData.ImageHeader + ')'"
          >
            <!--            <p class="user">参与人数：{{ New.NumberPate }}</p>-->
            <!--            <p class="count">创作字数：{{ New.NumberCreation }}</p>-->
            <!--            <p class="portfolio">作品集：{{ New.Numberwork }}</p>-->
            <span>
              <router-link
                :to="{
                  path: '/pc/browseArticles',
                  query: {
                    TitleNumber: TopData.TitleNumber,
                    Title: TopData.Title,
                  },
                }"
              >
                点击进入>>
              </router-link></span
            >
          </div>
        </div>
      </div>
      <div class="introduce">
        <div class="lamp"></div>
        <div class="title"></div>
        <div class="count">
          是一场基于交叉学科背景的群体智慧实验，<br />
          通过区块链技术保障群体协作的版 权及效益分配，<br />
          让“万人”共同创作文学作品。<br />
          每季将会有一个主题，<br />
          参与人可自行组队在限时日期内完成挑战，<br />
          即可获得丰厚奖励。
        </div>
      </div>
      <div class="timeline">
        <div class="title">往期回顾</div>
        <div class="data">
          <div
            class="block"
            v-for="(item, index) in ProjectData"
            :key="index"
            :style="'background-image: url(' + item.ImageHeader + ')'"
          >
            <router-link
              :to="{
                path: '/pc/browseArticles',
                query: { TitleNumber: item.TitleNumber, Title: item.Title },
              }"
            >
              <p class="TitleNumber">{{ item.TitleNumber }}</p>
              <p class="Title">#{{ item.Title }}#</p></router-link
            >
          </div>
        </div>
      </div>

      <div class="slogan">
        同我们一起共创添书宇宙<br /><span class="fs">三驱前传</span> |
        <span class="fs">神秘档案馆</span> | <span class="fs">添空之树</span>
      </div>
    </div>

    <Footer class="Footer"></Footer>
  </div>
</template>

<script>
import indexjs from "./index.js";
export default {
  ...indexjs,
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
