import Header from "../../conponents/Header";
import Footer from "../../conponents/Footer";
import { fetchData } from "@/api/home/home";

export default {
  data() {
    return {
      ProjectData: [],
      TopData: {},
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      fetchData().then((res) => {
        console.log(res);
        let ProjectData = res.ProjectData;
        let TopData = res.TopData;
        this.ProjectData = ProjectData;
        if (TopData) {
          this.TopData = TopData;
        }
      });
    },
  },
};
